
import { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component
class DocGetter extends Vue {
  $refs!: {
    anchor: HTMLAnchorElement;
  };

  public getDocName(contentDisposition: string): string {
    const dispositionWithDocName = contentDisposition
      .split(";")
      .find((disposition) => disposition.includes("filename"));

    let docName = "some-doc";

    if (dispositionWithDocName) {
      const matched = dispositionWithDocName
        .trim()
        .replace(/"/g, "")
        .match(/[a-z\d]+\.[a-z]+$/i);

      docName = matched ? matched[0] : docName;
    }

    return docName;
  }

  public downloadDocument(response: AxiosResponse): void {
    const contentDisposition: string = response.headers["content-disposition"];
    const { data } = response;

    const objURL = window.URL.createObjectURL(data);

    this.$refs.anchor.href = objURL;
    this.$refs.anchor.download = this.getDocName(contentDisposition);
    this.$refs.anchor.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}

export default DocGetter;
